<template>
    <a-popover v-model="show">
        <div slot="content">
            <div class="module-prop-text">
                <slot name="text"/>
            </div>
            <div class="module-btns">
                <button @click=" event('cancel') " class="btn module-prop-btn btn-cancel">取消</button>
                <button @click=" event('confirm') " class="btn module-prop-btn">确定</button>
            </div>
        </div>

        <slot/>
    </a-popover>
</template>

<script>
    export default {
        name: "AtPopover",
        model: {
            event: 'change',
            prop: 'active'
        },
        props: {
            text: String,
            active: Boolean
        },
        watch: {
            active() {
                this.show = this.active
            },
            show() {
                this.$emit('change', this.show)
            },
        },
        data() {
            return {
                show: false,
            }
        },
        methods: {
            event(name) {
                this.$emit(name)
            }
        }
    }
</script>

<style lang="less" scoped>
    .module-prop-text {
        width: 168px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
        margin-bottom: 16px;
    }

    .module-btns {
        display: flex;
        justify-content: right;
    }

    .module-prop-btn {
        width: 64px;
        height: 28px;
        background: #333333;
        border-radius: 14px;
        font-size: 12px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 28px;
        padding: 0;
    }

    .btn-cancel {
        color: #5A5E66;
        background: #FFFFFF;
        margin-right: 8px;
        border: 1px solid #DCDFE6;
    }
</style>