<template>
    <div>
        <div class="page-title">{{ module.title }}
            <button class="btn btn-new" @click="editItem(null)">创建新项目</button>
        </div>

        <div class="st-switch">
            <div class="st-title">
                <span class="text-tt">模块</span>
            </div>

            <div class="st-content">
                <a-spin :spinning="configLoading">
                    <a-radio-group v-model="module.enabled"
                                   @change="moduleChanged"
                                   :disabled="module.confirmVisible"
                    >
                        <a-radio :value="1">
                            <span class="radio-text">开启</span>
                        </a-radio>

                        <at-popover v-model="module.confirmVisible"
                                    @cancel="moduleConfirm(0)"
                                    @confirm="moduleConfirm(1)">
                            <div slot="text">
                                <div v-if="!module.enabled">开启后该版块对外显示，用户可正常查看</div>
                                <div v-else>隐藏后该板块不再显示，用户将无法查看</div>
                            </div>
                            <span></span>
                        </at-popover>

                        <a-radio :value="0">
                            <span class="radio-text">隐藏</span>
                        </a-radio>
                    </a-radio-group>
                </a-spin>
            </div>
        </div>


        <div v-if="listLoading" class="loading-block">
            <loader/>
        </div>
        <div v-else-if="!list || !list.length" class="loading-block">
            <p>{{ module.empty }}</p>
            <button class="btn btn-new" @click="editItem(null)">创建新项目</button>
        </div>
        <div class="st-list" v-else>

            <ca-item 
                v-for="(item,lk) in list" 
                :key="lk" 
                class="st-list-item" 
                :item="item"
                @qrcode="qrcodeItem" 
                @edit="editItem" 
                @poster="editPoster"
            />

            <div>
                <a-pagination
                        class="st-pagination"
                        v-if="pager.total > 0 "
                        :current="pager.current"
                        :total="pager.total"
                        :page-size="pager.size"
                        :show-total="(total, range) => `${range[0]}-${range[1]},共${total}条数据`"
                        @change="gotoPage"
                />
            </div>
        </div>


        <detail
                @success="getList"
                v-model="showDetailModal"
                :group="module.group"
                :item="detailItem"
                :share-enabled="app.course_share"
        />

        <a-modal width="380px" v-model="showMiniQrcodeModal" title="详情页快速码下载(用于宣传物料设计)" :footer="null">
            <div class="text-center mini-qrcode">
                <img style="width: 180px" v-if="miniQrcodeUrl" :src="miniQrcodeUrl" alt="">
            </div>
            <div class="qrcode-btns">
                <at-input v-model="miniQrcodeMemberId" class="mb-4" placeholder="请输入推荐人ID"/>
                <a v-if="detailItem" @click="download" class="btn mb-4">下载推广直达码</a>
                <div>
                    <small>主理人填入推荐人ID 后，下载推广直达码。包含该推荐关系二维码</small>
                </div>
            </div>
        </a-modal>

        <img v-if="miniQrcodeUrl" class="preload-img" @load="qrcodeReady" :src="miniQrcodeUrl" alt="">

        <PosterModal 
            v-model="showPosterModal" 
            :defaultBackground="posterItem.background"
            :defaultQrcode="posterItem.qrcode"
            @submit="handlePosterSubmit"
            @clean="handlePosterClean"
        ></PosterModal>
    </div>
</template>

<script>

import Detail from "./Detail";
import Loader from "../../../components/Loader";
import CaItem from "./com/CaItem";
import api from "../../../repo/api";
import AtPopover from "../../../components/AtPopover";
import axios from "axios";
import auth from "@/auth";
import AtInput from "@/components/AtInput.vue";
import PosterModal from "@/components/PosterModal.vue"

export default {
    name: "Course",
    components: {
        AtInput, 
        AtPopover, 
        CaItem, 
        Loader, 
        Detail,
        PosterModal,
    },
    props: {
        app: Object
    },
    data() {
        return {
            showMiniQrcodeModal: false,
            miniQrcodeUrl: "",
            miniQrcodeMemberId: "",
            module: {
                title: '',
                type: '',
                ek: '',
                group: '',
                enabled: 1,
                empty: '',
                confirmVisible: false,
            },
            showDetailModal: false,
            showPosterModal: false,
            detailItem: null,
            listLoading: true,
            configLoading: true,
            pager: {
                defaultCurrent: 1,
                current: 1,
                defaultPageSize: 10,
                total: 1,
                pageSize: 15,
            },
            query: {
                page: 1
            },
            list: [],
            modules: {
                act: {
                    title: '活动介绍设置',
                    empty: '暂无任何活动',
                    ek: 'activity_enabled',
                    group: 'activity',
                },
                ser: {
                    title: '服务介绍设置',
                    empty: '暂无任何服务',
                    ek: 'serve_enabled',
                    group: 'serve',
                },
                cla: {
                    title: '课程介绍设置',
                    empty: '暂无任何课程',
                    ek: 'course_enabled',
                    group: 'courses',
                }
            },
            posterItem: {
                background: undefined,
                qrcode: undefined,
            },
        }
    },
    watch: {
        '$route.params.module'() {
            this.init()
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            let md = this.$route.params.module
            let module = this.modules[md]

            if (!module) return

            this.module.confirmVisible = false
            this.module.ek = module.ek
            this.module.title = module.title
            this.module.group = module.group
            this.module.empty = module.empty

            this.getFirstList()
            this.getConfig()
        },
        getConfig() {
            this.configLoading = true

            api.get('/course/config', (data) => {
                this.configLoading = false
                this.module.enabled = data.data[this.module.ek] ? 1 : 0
            });

        },
        getList() {
            this.listLoading = true

            this.query.group = this.module.group

            api.get('/course/list', this.query, (data) => {

                this.listLoading = false

                this.list = data.data.data
                this.pager.current = data.data.current_page
                this.pager.total = data.data.total
                this.pager.size = data.data.per_page
            })
        },
        getFirstList() {
            this.query.page = 1
            this.getList()
        },
        gotoPage(page) {
            this.query.page = page
            this.getList()
        },
        editItem(item) {
            this.detailItem = item
            this.showDetailModal = true
        },
        qrcodeItem(item) {

            this.detailItem = item

            let url = axios.defaults.baseURL + '/collocation/com/mini-qrcode?';

            url += 'token=' + auth.getToken()
            url += '&_mini_app_id=' + auth.appId()

            url += '&path=packagesB/serviceDetail/serviceDetail?id=' + item.id;


            this.miniQrcodeUrl = '';

            setTimeout(() => {
                this.miniQrcodeUrl = encodeURI(url);
            }, 50)

            this.$loading.show()
        },
        download() {
            this.$loading.show()

            api.get('/ast-member/detail', {member_id: this.miniQrcodeMemberId}, (data) => {

                this.$loading.hide()

                if (data.code !== 0) {
                    return this.$message.data(data);
                }

                let url = axios.defaults.baseURL + '/collocation/com/mini-qrcode?';

                let path = 'packagesB/serviceDetail/serviceDetail?id=' + this.detailItem.id;

                path += '&ref=' + data.data.id

                url += 'token=' + auth.getToken()
                url += '&_mini_app_id=' + auth.appId()

                url += '&path=' + encodeURIComponent(path);

                url += '&name=' + this.detailItem.title + '推广码ID' + data.data.member_id

                console.log(encodeURI(url));
                window.open(encodeURI(url))
            });
        },
        qrcodeReady() {
            this.$loading.hide()
            this.showMiniQrcodeModal = true
        },
        moduleChanged() {
            this.module.confirmVisible = true
            this.module.enabled = this.module.enabled === 1 ? 0 : 1;
        },
        moduleConfirm(sure) {

            this.module.confirmVisible = false

            if (!sure) return

            let enabled = this.module.enabled === 1 ? 0 : 1

            api.post('/course/config', {
                key: this.module.ek,
                enabled,
            }, (data) => {

                this.$message.data(data)

                if (data.code === 0) {
                    this.module.enabled = enabled;
                }

            })
        },
        editPoster(item) {
            this.showPosterModal = true
            this.detailItem = item
            this.posterItem.background = {}
            this.posterItem.qrcode = {}
            if (item.poster) {
                this.$set(this.posterItem.background, 'imageUrl', item.poster)
            }
            if (item.poster_frame?.background) {
                this.$set(this.posterItem.background, 'w', item.poster_frame.background.w)
                this.$set(this.posterItem.background, 'h', item.poster_frame.background.h)
            }
            if (item.poster_frame?.qrcode) {
                this.$set(this.posterItem.qrcode, 'w', item.poster_frame.qrcode.w)
                this.$set(this.posterItem.qrcode, 'h', item.poster_frame.qrcode.h)
                this.$set(this.posterItem.qrcode, 'l', item.poster_frame.qrcode.l)
                this.$set(this.posterItem.qrcode, 't', item.poster_frame.qrcode.t)
            }
        },
        handlePosterSubmit({background, qrcode}) {
            const reqData = {
            id: this.detailItem.id,
            poster: background.imageUrl,
            poster_frame: {
                background: {
                    w: background.w,
                    h: background.h,
                },
                qrcode: {
                    w: qrcode.w,
                    h: qrcode.h,
                    l: qrcode.l,
                    t: qrcode.t,
                },
            },
            }
            api.postCourseUpdatePosterInfo(reqData, (resData) => {
                if (resData.code === 0) {
                    this.$message.success("成功")
                    setTimeout(() => {
                        this.showPosterModal = false
                        this.getList()
                    }, 500)
                } else {
                    this.$message.error(resData.msg)
                }
            })
        },
        handlePosterClean() {
            this.showPosterModal = false
            const reqData = {
                id: this.detailItem.id,
                poster: "",
                poster_frame: null,
            }
            api.postCourseUpdatePosterInfo(reqData, (resData) => {
                if (resData.code === 0) {
                    this.$message.success("成功")
                    setTimeout(() => {
                        this.getList()
                    }, 500)
                } else {
                    this.$message.error(resData.msg)
                }
            })
        },
    }
}
</script>

<style lang="less" scoped>
.st-switch {
    display: flex;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 16px;
    align-items: center;

    .st-title {
        margin-right: 24px;
    }
}

.st-list {
    margin-top: 35px;
    margin-bottom: 35px;
}

.st-list-item {
    margin-bottom: 32px;
}

.btn-new {
    //width: 102px;
    height: 32px;
    background: #333333;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 22px;
    float: right;
}

.mini-qrcode {
    margin-bottom: 16px;
}

.qrcode-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
</style>